import React from 'react';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';

import Footer from './footer';
import Header from './header';

const theme = {
  breakpoint: '768px',
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Abraham H. Howland, Jr. Lodge</title>
      </Helmet>

      <Container>
        <Header />
        {children}
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Layout.defaultProps = {
  children: [],
};
