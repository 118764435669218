import React, { useState } from 'react';

import { graphql, StaticQuery, navigate } from 'gatsby';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from 'shards-react';
import styled from 'styled-components';

import SquareAndCompass from '../img/squareandcompass.png';

const StyledNavBar = styled(Navbar)`
  background: linear-gradient(90deg, #212121 -10%, #182848 100%);
  display: flex;
  justify-content: center;

  .navbar-nav .nav-link {
    color: #fff;
  }

  @media (min-width: 768px) {
    .navbar-collapse {
      justify-content: flex-end;
    }
  }

  @media (min-width: 1921px) {
    a {
      font-size: 2rem;
    }
  }
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

export default function Header() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
                subMenu {
                  name
                  link
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { menuLinks, title },
        },
      }) => (
        <StyledNavBar type="dark" expand="md">
          <NavbarBrand href="/">
            <Logo src={SquareAndCompass} />
            {title}
          </NavbarBrand>
          <NavbarToggler onClick={() => setCollapseOpen(!collapseOpen)} />

          <Collapse open={collapseOpen} navbar>
            <Nav navbar>
              {menuLinks.map((menuLink) => {
                if (menuLink.subMenu) {
                  return (
                    <Dropdown
                      key={menuLink.name}
                      open={dropdownOpen === menuLink.name}
                      toggle={() => {
                        if (menuLink.name === dropdownOpen) {
                          setDropdownOpen(null);
                        } else {
                          setDropdownOpen(menuLink.name);
                        }
                      }}
                    >
                      <DropdownToggle nav caret>
                        {menuLink.name}
                      </DropdownToggle>
                      <DropdownMenu>
                        {menuLink.subMenu.map((item) => (
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(item.link, {
                                replace: true,
                              });
                            }}
                          >
                            {item.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  );
                }
                return (
                  <NavItem>
                    <NavLink href={menuLink.link}>{menuLink.name}</NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </StyledNavBar>
      )}
    />
  );
}
