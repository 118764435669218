import React from 'react';
import styled from 'styled-components';

const Foot = styled.footer`
  background-color: #212121;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 'blurb' 'links' 'copyright';

  @media (min-width: ${(props) => props.theme.breakpoint}) {
    grid-gap: 5px 300px;
    grid-template-areas: 'blurb links' 'copyright copyright';
  }
`;

const Item = styled.div`
  min-height: 100px;
`;
const StyledEm = styled.em`
  font-style: italic;
`;

const LinksTitle = styled.h5`
  color: #a0a0a0;
  font-size: 1.2rem;
  grid-area: links;
`;

const Link = styled.a`
  color: #fff;
  line-height: 1.6rem;

  :hover {
    color: #a0a0a0;
  }
`;

const Blurb = styled(Item)`
  grid-area: blurb;

  h5 {
    color: #a0a0a0;
  }
`;

const Links = styled(Item)`
  grid-area: links;
`;

const Copyright = styled(Item)`
  grid-area: copyright;

  @media (min-width: ${(props) => props.theme.breakpoint}) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  padding-inline-start: 0;

  a {
    pointer: cursor;
  }
`;
export default function Footer() {
  return (
    <Foot>
      <Container>
        <Blurb>
          <h5>Abraham H. Howland, Jr. Lodge</h5>
          <p>
            <strong>
              <StyledEm>Brotherhood. Relief. Truth.</StyledEm>
            </strong>
          </p>
          <p>
            Abraham H. Howland Jr. Lodge is dedicated to fellowship and service
            to the community. The members of the lodge continue to aspire
            towards the fulfillment of their Masonic virtues through their
            actions in charity and brotherhood every day.
          </p>
          <p>Monthly meetings are held on the second Tuesday of each month.</p>
        </Blurb>
        <Links>
          <LinksTitle>Links</LinksTitle>
          <StyledList>
            <li>
              <Link href="https://www.massfreemasonry.org">
                Massachusetts Freemasonry
              </Link>
            </li>
            <li>
              <Link href="https://www.facebook.com/19thdistrict">
                19th Masonic District
              </Link>
            </li>
          </StyledList>
        </Links>
        <Copyright className="footer-copyright">
          <div>
            © {new Date().getFullYear()} Abraham H. Howland, Jr. Lodge, A.F.
            &amp; A.M.
          </div>
        </Copyright>
      </Container>
    </Foot>
  );
}
